<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { OrbitSpinner } from 'epic-spinners';

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    OrbitSpinner
  },
  computed: {
    ...mapGetters(['getDTLocale'])
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/batch/reasons`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data.reasons;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async batchMarkReasons(reason, idx) {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          reason: reason,
          reasons: this.reasons
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/batch/reasons`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          if(idx !== null) {
            this.data.splice(idx, 1);
          } else {
            this.getData();
            this.$swal({
              icon: 'success',
              title: 'Requested revision'
            });
          }
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      inProgress: false,
      data: {},
      reasons: []
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col">
          <div class="card card-body">
            <h4>Batch Tool</h4>
            <div class="row">
              <div class="col">
                <button class="btn btn-primary btn-block" :disabled="(inProgress)" v-on:click="batchMarkReasons('', null)">
                  <orbit-spinner
                      v-if="inProgress"
                      :animation-duration="1200"
                      :size="18"
                      class="align-middle d-inline-block"
                  />
                  Request revision
                </button>
              </div>
            </div>

            <div class="table-responsive mb-0 mt-4">
              <table class="table align-middle table-nowrap">
                <thead>
                <tr>
                  <th scope="col" style="width: 100px;"></th>
                  <th scope="col">Reason</th>
                  <th scope="col">Occurrences</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(set, index) in data" :key="set.string" >
                  <td class="text-center">
                    <input :id="index" :value="set.string" :name="index" type="checkbox" v-model="reasons" />
                  </td>
                  <td>
                    <h4 class="font-size-14 text-code">
                      {{set.string}}
                    </h4>
                  </td>
                  <td>
                    <b class="font-size-14 text-white">
                      {{ $n(set.occurrences, {locale: 'de'}) }}
                    </b>
                  </td>
                  <td>
                    <button class="btn btn-primary" :disabled="(inProgress)" v-on:click="batchMarkReasons(set.string, index)">
                      <orbit-spinner
                          v-if="inProgress"
                          :animation-duration="1200"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      Request revision
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
